import { Options, Vue } from 'vue-class-component'
import CommonFunctions from '@/components/Utility/Common'
import { Menus } from '@/services/MenusDataService'
import SysMenu, { MenuTypePlacementFriendlyType } from '@/types/SysMenu'

type dataReturnType = { menus: any; error: any; }
export default class theFooter extends Vue {
  error: any = null
  private menus: SysMenu[] = []
  private currentListSortingOrder = 'menu_position:asc'

  readonly name : string = 'theFooter'
  data (): dataReturnType {
    return {
      menus: this.menus,
      error: this.error
    }
  }

  // public getBottomMenu () : SysMenu[] {
  //   return this.menus
  //     .filter((element) => { return (element.menu_status === true) })
  //     .filter((element) => { return (element.menu_submenu === false) })
  //     .filter((element) => { return (element.menu_type_id.menu_type_placering === MenuTypePlacementFriendlyType.Bund) })
  // }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public navigationRouter (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : void {
    console.log(url)
    CommonFunctions.navigationRouter(url, optionalPath1, optionalPath2, optionalPath3)
    // if (url.toLowerCase().includes('http') || url.toLowerCase().includes('www')) {
    //   window.open(url, 'noopener')

    //   return url
    // }
    // if (url === '#') {
    //   return url
    // }
    // if (url === 'club-list') {
    //   this.$router.push('/klubber')

    //   return url
    // }
    // if (url === 'member-list') {
    //   this.$router.push('/medlemmer')

    //   return url
    // }
    // if (url === 'member-registration') {
    //   this.$router.push('/medlemmer/nyt-medlem')

    //   return url
    // }
    // if (url === 'competition-list') {
    //   this.$router.push('/staevner')

    //   return url
    // }
    // if (url === 'evnet-list') {
    //   this.$router.push('/arrangementer')

    //   return url
    // }
    // if (url === 'sponsor-list') {
    //   this.$router.push('/sponsorer')

    //   return url
    // }
    // if (url === 'news-archive') {
    //   this.$router.push('/nyheder')

    //   return url
    // }
    // if (url === 'ranking-main') {
    //   this.$router.push({ path: '/staevner/ranglister' })

    //   return url
    // }
    // if (url === 'the-calendar') {
    //   this.$router.push({ path: '/kalender' })

    //   return url
    // }
    // if (url === 'tournaments') {
    //   this.$router.push({ name: 'FrontEndTournaments' })
    //   return url
    // } else {
    //   console.log(url)

    //   this.$router.push({ name: 'Pages', params: { pathSlug1: this.convertToSlug(optionalPath1), pathSlug2: this.convertToSlug(optionalPath2), pathSlug3: this.convertToSlug(optionalPath3), slug: url } })

    //   return url
    // }
  }

  public retrieveMenus () : void {
    Menus.MenusDataService.getAll(this.currentListSortingOrder, null, 'menu_status=true&menu_type_id.menu_type_placering=Bund&menu_type_id.domaene_id=1')
      .then((response) => {
        this.menus = response.data
      })
      .catch((err) => {
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    this.retrieveMenus()
  }
}
