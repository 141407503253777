import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class SponsorsDataService extends APIBaseFunctions {
  private cachedSponsorsDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = SponsorsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedSponsorsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/sponsors?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/sponsors?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`sponsors/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/sponsors/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/sponsors', data)
  }

  update (id: string, data: any) {
    return http.put(`/sponsors/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/sponsors/${id}`)
  }

  deleteAll () {
    return http.delete('sponsors/')
  }

  findBySponsorStatus (status: boolean, sortingOrder = '') {
    let sortingOrderParameter = ''

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/sponsors?sponsor_status=${status}${sortingOrderParameter}`)
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '') {
    let retVal
    let sortingOrderParameter = ''

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }
    // Do a search in sponsor names for the search term
    if (ignoreStatus) {
      retVal = http.get(`/sponsors?_where[sponsor_sponsornavn_contains]=${searchTerm}${sortingOrderParameter}`)
    } else {
      retVal = http.get(`/sponsors?sponsor_status=${status}&_where[sponsor_sponsornavn_contains]=${searchTerm}${sortingOrderParameter}`)
    }

    return retVal
  }
}

export default new SponsorsDataService()
