import { Vue, Options } from 'vue-class-component'
import CommonFunctions from '@/components/Utility/Common'
import { Menus } from '@/services/MenusDataService'
import SysMenu, { MenuTypePlacementFriendlyType, SysMenuMenuType } from '@/types/SysMenu'
import AuthService from '@/services/AuthService'
import router from '@/router'
import { admZubPth } from '@/components/Utility/aXdYmZ'
import MembersDataService from '@/services/MembersDataService'
import SysMember from '@/types/SysMember'
import store from '@/store'

type dataReturnType = { menus: SysMenu[], openMenu : boolean, nestedOne: number, nestedTwo: number, nestedThree: number}

export default class mobileHeader extends Vue {
  private menus: SysMenu[] = []
  private openMenu = false
  private nestedOne = 0
  private nestedTwo = 0
  private nestedThree = 0
  $Message: any
  data (): dataReturnType {
    return {
      menus: this.menus,
      openMenu: this.openMenu,
      nestedOne: this.nestedOne,
      nestedTwo: this.nestedTwo,
      nestedThree: this.nestedThree
    }
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public navigation (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : void {
    console.log(url)
    this.openCloseMenu()
    this.resetMenuValue()
    CommonFunctions.navigationRouter(url, optionalPath1, optionalPath2, optionalPath3)
  }

  public resetMenuValue () {
    this.nestedOne = 0
    this.nestedTwo = 0
    this.nestedThree = 0
  }

  public openCloseMenu () {
    this.openMenu = !this.openMenu
    this.resetMenuValue()
  }

  public nestOneClick (menuId: number) {
    if (this.nestedOne === menuId) {
      this.nestedOne = 0
      this.nestedTwo = 0
      this.nestedThree = 0
    } else {
      this.nestedOne = menuId
    }
  }

  public nestTwoClick (menuId: number) {
    if (this.nestedTwo === menuId) {
      this.nestedTwo = 0
      this.nestedThree = 0
    } else {
      this.nestedTwo = menuId
    }
  }

  public nestThreeClick (menuId: number) {
    if (this.nestedThree === menuId) {
      this.nestedThree = 0
    } else {
      this.nestedThree = menuId
    }
  }

  public getTopMenu () : SysMenu[] {
    return this.menus
      // .filter((element) => { return (element.menu_status === true) })
      .filter((element) => { return (element.menu_submenu === false) })
      .filter((element) => { return (element.menu_type_id.menu_type_placering === MenuTypePlacementFriendlyType.Top) })
  }

  public subMenu (menuId: number) : any {
    const subMenuTitel: any = []

    for (const subMenuItem of this.menus) {
      if (subMenuItem.menu_submenu_id === menuId && subMenuItem.menu_status === true && subMenuItem.menu_submenu === true) {
        subMenuTitel.push(subMenuItem)
      }
    }

    return subMenuTitel
  }

  public subSubMenu (subMenuId: number) : any {
    const subSubMenuTitel: any = []

    for (const subSubMenuItem of this.menus) {
      if (subSubMenuItem.menu_submenu_id === subMenuId && subSubMenuItem.menu_status === true && subSubMenuItem.menu_submenu === true) {
        subSubMenuTitel.push(subSubMenuItem)
      }
    }

    return subSubMenuTitel
  }

  public subSubSubMenu (subSubMenuId: number) : any {
    const subSubSubMenuTitel: any = []

    for (const subSubSubMenuItem of this.menus) {
      if (subSubSubMenuItem.menu_submenu_id === subSubMenuId && subSubSubMenuItem.menu_status === true && subSubSubMenuItem.menu_submenu === true) {
        subSubSubMenuTitel.push(subSubSubMenuItem)
      }
    }

    return subSubSubMenuTitel
  }

  public retrieveMenus () : void {
    Menus.MenusDataService.getAll('menu_position:asc', null, 'menu_status=true&menu_type_id.domaene_id=1')
      .then((response) => {
        this.menus = response.data
        this.$forceUpdate()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public home () : void {
    this.$router.push('/')
  }

  public userLogout () {
    AuthService.logout(true)
  }

  public login () : void {
    router.push({ path: '/medlemsportal/login' })
  }

  public getUserName () : string {
    let retVal = ''
    if (localStorage.getItem('user') !== null) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
      retVal = userDataObject.username
    }
    return retVal
  }

  public async toMemberPortal () : Promise<void> {
    if (localStorage.getItem('user') === null) {
      console.log('ERROR: no user')
      return
    }
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
    let members: SysMember[] = []
    MembersDataService.getAll('', null, 'user_id.id=' + Number(userDataObject.id))
      .then((response) => {
        members = response.data

        if (members.length > 1) {
          return this.$Message.danger({ text: 'Fejl: Flere med samme brugernavn' })
        }
        if (members[0].user_id.usrgroup === 2) {
          return this.$router.push({ name: 'AdminHome', path: `${admZubPth}` })
        }
        if (members.length === 0) {
          return this.$Message.warning({ text: 'Fejl: Ingen med det brugernavn' })
        }
        console.log(members[0])
        return this.$router.push({ name: 'MemberPortalMain', params: { userSlug: this.convertUserNameToSlug(Number(members[0].id), members[0].user_id.username) } })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  // Changes the button to a logout button if false
  public currentlyLoggedIn () : boolean {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      return true
    }
    return false
  }

  public userLoggedIn () {
    return store.state.logintype
  }

  async mounted () : Promise<void> {
    this.retrieveMenus()
  }
}
