export enum MenuTypePlacementFriendlyType {
  Top = <any>'Top',
  Bund = <any>'Bund',
  Sidebar = <any>'Sidebar',
  Topbar = <any>'Topbar'
}

export interface SysMenuDomain {
  id: null
  domaene_status: boolean
  domaene_titel: string
  domaene_url: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysMenuMenuType {
  id: null
  menu_type_status: boolean
  menu_type_titel: string
  menu_type_placering: MenuTypePlacementFriendlyType
  domaene_id: SysMenuDomain
}

export interface SysMenuPost {
  id: null
  indlaeg_status: boolean
  indlaeg_udgivet: string
  indlaeg_visninger: number
  indlaeg_version: number
  indlaeg_forfatter: number
  indlaeg_kategori_id: number
  indlaeg_versionerings_id: number[]
  indlaeg_type: number
  menus_id: number[]
  published_at: string
  created_at: string
  updated_at: string
}

export default interface SysMenu {
  id: null
  menu_status: boolean
  menu_titel: string
  menu_type_id: SysMenuMenuType
  indlaegs_id: SysMenuPost[]
  menu_url: string
  menu_submenu: boolean
  menu_submenu_id: number
  menu_position: number
  published_at: string
  created_at: string
  updated_at: string
}
