import { APIURL } from '@/components/Utility/APIBase'
import axios from 'axios'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
    // 'Upgrade-Insecure-Requests': '1',
    // Pragma: 'no-cache',
    // 'Cache-Control': 'no-cache',
    // Origin: APIURL
  },
  maxContentLength: 256 * 1024,
  maxBodyLength: 256 * 1024,
  maxRedirects: 3
}

class AuthService {
  async login (identifier: string, password: string) : Promise<any> {
    return axios
      .post(APIURL + '/auth/local', {
        identifier: identifier,
        password: password
      }, axiosConfig)
      .then(response => {
        if (response.data.jwt !== undefined && response.data.jwt != null) {
          localStorage.setItem('apitoken', response.data.jwt)
          // console.log('Login apitoken: ' + response.data.jwt)

          if (response.status === 200) {
            localStorage.setItem('status', true.toString())
          }
        }

        if (response.data.user !== undefined) {
          localStorage.setItem('user', JSON.stringify(response.data.user))
        }

        // console.log('Login: response = ' + JSON.stringify(response))

        if (response.status === 401) {
          // auto logout if 401 response returned from api
          this.logout()
        }

        return response.data
      })
  }

  logout (reload = false) {
    localStorage.removeItem('user')
    localStorage.removeItem('apitoken')
    localStorage.setItem('status', false.toString())
    localStorage.setItem('logintype', false.toString())

    // FIXME: This should actually try a login as the guest user here. WIP
    if (reload) {
      window.location.reload()
    }
  }

  /* register(username: string, email: string, password: string) {
    return axios.post(APIURL + '/signup', {
      username,
      email,
      password
    })
  } */
}

export default new AuthService()
