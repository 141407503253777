export const defaultAPILimit = -1
export const APIURL = 'https://api.dart-ddu.dk:443'
// export const APIURL = process.env.VUE_APP_API_URL
export const ABLYAPIKEY = process.env.VUE_APP_ABLY_KEY

export interface APIDataSliceObj {
  slicemode: number // value: bit 7 = 0 do not update the cached data object of the data service, bit 7 = 1 do update the cached data object of the data service; bit 0-6 = 0 use values of start and limit fields unchanged, = 1 use values of page and pagesize and calculate values of start and limit fields based upon them, = 2 set values of start and limit fields to the first page using pagesize field value, = 3 set values of start and limit fields to the last page using values of the pagesize and totalcount fields
  start: number
  limit: number
  page: number // first page starts with value 0
  pagesize: number
  totalcount: number
}

export class APIBaseFunctions {
  public static dataSliceGotoPage (sliceObj: APIDataSliceObj) : void {
    sliceObj.start = sliceObj.page * sliceObj.pagesize
    sliceObj.limit = sliceObj.pagesize
  }

  public static dataSliceCalcPage (sliceObj: APIDataSliceObj) : void {
    sliceObj.page = Math.floor(sliceObj.start / sliceObj.pagesize)
  }

  public static dataSliceGotoBeginPage (sliceObj: APIDataSliceObj) : void {
    sliceObj.start = 0
    sliceObj.page = 0
    sliceObj.limit = sliceObj.pagesize
  }

  public static dataSliceGotoEndPage (sliceObj: APIDataSliceObj) : void {
    sliceObj.page = Math.ceil(sliceObj.totalcount / sliceObj.pagesize) - 1
    sliceObj.start = sliceObj.totalcount >= sliceObj.pagesize ? sliceObj.page * sliceObj.pagesize : 0
    sliceObj.limit = sliceObj.pagesize
  }

  public static processDataSliceObj (cachedServicesDataSliceObjRef: { cachedServicesDataSliceObj: APIDataSliceObj }, dataSliceObj: APIDataSliceObj | null = null) : APIDataSliceObj {
    let currentDataSliceObj = {} as APIDataSliceObj

    if (dataSliceObj != null) {
      // Update cached data object values if requested
      if ((dataSliceObj.slicemode & 128) > 0) {
        currentDataSliceObj = dataSliceObj
        currentDataSliceObj.slicemode = dataSliceObj.slicemode - 128
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.slicemode = currentDataSliceObj.slicemode
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.start = currentDataSliceObj.start
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.limit = currentDataSliceObj.limit
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.page = currentDataSliceObj.page
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.pagesize = currentDataSliceObj.pagesize
        cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.totalcount = currentDataSliceObj.totalcount
        // console.log('[processDataSliceObj] 1 - cachedServicesDataSliceObj = ' + JSON.stringify(cachedServicesDataSliceObjRef.cachedServicesDataSliceObj))
      } else {
        // Do not update cached data object values, but still do use the given data object values for the current API access
        currentDataSliceObj = dataSliceObj
      }
    } else {
      // Reuse cached data object values
      // currentDataSliceObj = cachedCompetitionsDataSliceObjRef.cachedCompetitionsDataSliceObj
      currentDataSliceObj.slicemode = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.slicemode
      currentDataSliceObj.start = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.start
      currentDataSliceObj.limit = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.limit
      currentDataSliceObj.page = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.page
      currentDataSliceObj.pagesize = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.pagesize
      currentDataSliceObj.totalcount = cachedServicesDataSliceObjRef.cachedServicesDataSliceObj.totalcount
    }

    switch (currentDataSliceObj.slicemode) {
      case 3:
        // Go to the last page based upon the pagesize data and update start and limit data accordingly
        this.dataSliceGotoEndPage(currentDataSliceObj)
        break
      case 2:
        // Go to the first page based upon the pagesize data and update start and limit data accordingly
        this.dataSliceGotoBeginPage(currentDataSliceObj)
        break
      case 1:
        // Use page and pagesize data to update start and limit data accordingly
        this.dataSliceGotoPage(currentDataSliceObj)
        break
      case 0:
      default:
        // Nothing to do
    }

    return currentDataSliceObj
  }
}

// export default new APIBaseFunctions()
