import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adminContainer"
}
const _hoisted_2 = { class: "adminSidebar" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 1,
  class: "frontContainer"
}
const _hoisted_5 = { class: "frontHeader" }
const _hoisted_6 = { class: "frontHeaderMobile" }
const _hoisted_7 = {
  key: 0,
  class: "frontGrid"
}
const _hoisted_8 = { class: "frontBody" }
const _hoisted_9 = { class: "frontSidebar" }
const _hoisted_10 = { class: "frontFooter" }
const _hoisted_11 = {
  key: 1,
  class: "gridWithoutSidebar"
}
const _hoisted_12 = { class: "body" }
const _hoisted_13 = { class: "frontFooter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_sidebar = _resolveComponent("admin-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_front_header = _resolveComponent("front-header")!
  const _component_mobileSidebar = _resolveComponent("mobileSidebar")!
  const _component_front_sidebar = _resolveComponent("front-sidebar")!
  const _component_front_footer = _resolveComponent("front-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.rtPthAdmEval(_ctx.$route.path))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_admin_sidebar)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_front_header)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_mobileSidebar)
          ]),
          (!_ctx.noSidebar(_ctx.$route.name))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_router_view)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_front_sidebar)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_front_footer)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_router_view)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_front_footer)
                ])
              ]))
        ]))
  ]))
}