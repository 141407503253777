import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Menus extends APIBaseFunctions {
  static MenusDataService = class {
    private static cachedMenusDataDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Menus.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMenusDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/menus?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/menus?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/menus/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/menus/count' + (extraCountParameter.length > 0 ? `?&${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/menus', data)
    }

    static update (id: string, data: any) {
      return http.put(`/menus/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/menus/${id}`)
    }

    static deleteAll () {
      return http.delete('/menus')
    }

    static findByMenuStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Menus.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMenusDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/menus?menu_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Menus.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMenusDataDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/menus?_where[_or][0][menu_titel_contains]=${searchTerm}&_where[_or][1][menu_type_id.menu_type_titel_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/menus?menu_status=${status}&_where[_or][0][menu_titel_contains]=${searchTerm}&_where[_or][1][menu_type_id.menu_type_titel_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static MenuTypesDataService = class {
    private static cachedMenuTypesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Menus.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedMenuTypesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/menu-types?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/menu-types?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/menu-types/${id}`)
    }

    static getCount () {
      return http.get('/menu-types/count')
    }

    static create (data: any) {
      return http.post('/menu-types', data)
    }

    static update (id: string, data: any) {
      return http.put(`/menu-types/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/menu-types/${id}`)
    }

    static deleteAll () {
      return http.delete('/menu-types')
    }
  }
}

export default new Menus()
