import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import { Menus } from '@/services/MenusDataService'
import SysMenu, { MenuTypePlacementFriendlyType, SysMenuMenuType } from '@/types/SysMenu'
import AuthService from '@/services/AuthService'
import router from '@/router'
import MembersDataService from '@/services/MembersDataService'
import SysMember from '@/types/SysMember'
import { admZubPth } from '@/components/Utility/aXdYmZ'
import store from '@/store'

type dataReturnType = {menus: any; error: any; }
type menuTypeDataType = { menuTypeTitel: string }

export default class theHeader extends Vue {
  $Message: any
  error: any = null
  private menus: SysMenu[] = []
  // private menutypes: SysMenuMenuType[] = []
  private currentListSortingOrder = 'menu_position:asc'
  // private subMenuItem: any

  readonly name : string = 'theHeader'
  data (): dataReturnType {
    return {
      menus: this.menus,
      error: this.error
    }
  }

  // public navigator (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : string {
  //   return CommonFunctions.navigationRouter(url, optionalPath1, optionalPath2, optionalPath3)
  // }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public navigationRouter (url: string, optionalPath1 = '', optionalPath2 = '', optionalPath3 = '') : void {
    console.log(url)
    CommonFunctions.navigationRouter(url, optionalPath1, optionalPath2, optionalPath3)
    // if (url.toLowerCase().includes('http') || url.toLowerCase().includes('www')) {
    //   window.open(url, 'noopener')

    //   return url
    // }
    // if (url === '#') {
    //   return url
    // }
    // if (url === 'club-list') {
    //   this.$router.push('/klubber')

    //   return url
    // }
    // if (url === 'member-list') {
    //   this.$router.push('/medlemmer')

    //   return url
    // }
    // if (url === 'member-registration') {
    //   this.$router.push('/medlemmer/nyt-medlem')

    //   return url
    // }
    // if (url === 'competition-list') {
    //   this.$router.push('/staevner')

    //   return url
    // }
    // if (url === 'event-list') {
    //   this.$router.push('/arrangementer')

    //   return url
    // }
    // if (url === 'sponsor-list') {
    //   this.$router.push('/sponsorer')

    //   return url
    // }
    // if (url === 'news-archive') {
    //   this.$router.push('/nyheder')

    //   return url
    // }
    // if (url === 'ranking-main') {
    //   this.$router.push({ path: '/staevner/ranglister' })

    //   return url
    // }
    // if (url === 'the-calendar') {
    //   this.$router.push({ path: '/kalender' })

    //   return url
    // } if (url === 'tournaments') {
    //   this.$router.push({ name: 'FrontEndTournaments' })
    //   return url
    // } else {
    //   console.log(url)

    //   this.$router.push({ name: 'Pages', params: { pathSlug1: this.convertToSlug(optionalPath1), pathSlug2: this.convertToSlug(optionalPath2), pathSlug3: this.convertToSlug(optionalPath3), slug: url } })

    //   return url
    // }
  }

  public getUserName () : string {
    let retVal = ''
    if (localStorage.getItem('user') !== null) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
      retVal = userDataObject.username
    }
    return retVal
  }

  public async toMemberPortal () : Promise<void> {
    if (localStorage.getItem('user') === null) {
      console.log('ERROR: no user')
      return
    }
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
    let members: SysMember[] = []
    MembersDataService.getAll('', null, 'user_id.id=' + Number(userDataObject.id))
      .then((response) => {
        members = response.data

        if (members.length > 1) {
          return this.$Message.danger({ text: 'Fejl: Flere med samme brugernavn' })
        }
        if (members[0].user_id.usrgroup === 2) {
          return this.$router.push({ name: 'AdminHome', path: `${admZubPth}` })
        }
        if (members.length === 0) {
          return this.$Message.warning({ text: 'Fejl: Ingen med det brugernavn' })
        }
        console.log(members[0])
        return this.$router.push({ name: 'MemberPortalMain', params: { userSlug: this.convertUserNameToSlug(Number(members[0].id), members[0].user_id.username) } })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public convertUserNameToSlug (id: number, userName: string) : string {
    return id.toString() + '-' + CommonFunctions.slugify(userName)
  }

  public getLink () : string {
    let retVal = ''

    if (localStorage.getItem('user') !== null) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
      retVal = userDataObject.id.toString() + '-' + userDataObject.username.toString().replaceAll(' ', '-')
    }
    return retVal
  }

  public getTopMenu () : SysMenu[] {
    return this.menus
      // .filter((element) => { return (element.menu_status === true) })
      .filter((element) => { return (element.menu_submenu === false) })
      .filter((element) => { return (element.menu_type_id.menu_type_placering === MenuTypePlacementFriendlyType.Top) })
  }

  public getTopBar () : SysMenu[] {
    return this.menus
      // .filter((element) => { return (element.menu_status === true) })
      .filter((element) => { return (element.menu_submenu === false) })
      .filter((element) => { return (element.menu_type_id.menu_type_placering === MenuTypePlacementFriendlyType.Topbar) })
  }

  public subMenu (menuId: number) : SysMenu[] {
    const subMenuTitel: SysMenu[] = []

    for (const subMenuItem of this.menus) {
      if (subMenuItem.menu_submenu_id === menuId && subMenuItem.menu_status === true && subMenuItem.menu_submenu === true) {
        subMenuTitel.push(subMenuItem)
      }
    }

    return subMenuTitel
  }

  public subSubMenu (subMenuId: number) : SysMenu[] {
    const subSubMenuTitel: SysMenu[] = []

    for (const subSubMenuItem of this.menus) {
      if (subSubMenuItem.menu_submenu_id === subMenuId && subSubMenuItem.menu_status === true && subSubMenuItem.menu_submenu === true) {
        subSubMenuTitel.push(subSubMenuItem)
      }
    }

    return subSubMenuTitel
  }

  public subSubSubMenu (subSubMenuId: number) : SysMenu[] {
    const subSubSubMenuTitel: SysMenu[] = []

    for (const subSubSubMenuItem of this.menus) {
      if (subSubSubMenuItem.menu_submenu_id === subSubMenuId && subSubSubMenuItem.menu_status === true && subSubSubMenuItem.menu_submenu === true) {
        subSubSubMenuTitel.push(subSubSubMenuItem)
      }
    }

    return subSubSubMenuTitel
  }

  public retrieveMenus () : void {
    console.log('Fetching top menu data from API.')

    Menus.MenusDataService.getAll(this.currentListSortingOrder, null, 'menu_status=true&menu_type_id.domaene_id=1')
      .then((response) => {
        this.menus = response.data

        this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public home () : void {
    this.$router.push('/')
  }

  public userLogout () {
    AuthService.logout(true)
  }

  public login () : void {
    router.push({ path: '/medlemsportal/login' })
  }

  // Changes the button to a logout button if false
  public currentlyLoggedIn () : boolean {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
      // console.log(userDataObject)
      if (userDataObject.id !== 1) {
        store.dispatch('loadStoredState')
        return true
      }
    }
    return false
  }

  public userLoggedIn () {
    return store.state.logintype
  }

  async mounted () : Promise<void> {
    this.retrieveMenus()
    this.currentlyLoggedIn()
    // this.retrieveMenuTypes()
  }
}
